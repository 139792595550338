<template>
  <div>
    <v-chart class='chart bg-white' :option='lineAndBar' style='height: 400px; width:1300px;' />
  </div>
</template>

<script>
import { LineChart, BarChart } from 'echarts/charts'
import { use } from 'echarts/core'
use([
  LineChart, 
  BarChart, 
])
export default {
  name: 'HoldingsBarChart',
  props: [
    'barChartData',
  ],
  data() {
    return {
      lineAndBar: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '투자금액',
            min: 0,
            max: 0,
            interval: 200,
            axisLabel: {
              formatter: '{value} 억원'
            }
          },
          {
            type: 'value',
            name: '수익금액',
            min: 0,
            max: 0,
            interval: 100,
            axisLabel: {
              formatter: '{value} 억원'
            }
          }
        ],
        series: [
          {
            name: '투자금액',
            type: 'bar',
            color: ['#3267A2'],
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 억원';
              }
            },
            data: []
          },
          {
            name: '수익금액',
            type: 'line',
            color: ['#52527F'],
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 억원';
              }
            },
            data: []
          }
        ]
      },
    }
  },
  watch: {
    'barChartData.isChange': {
      handler: function () {
        if (this.barChartData.isChange === 'Y') {
          this.refreshData ()
        }
      },
      immediate: true
    },
  },
  methods: {
    refreshData () {
      this.lineAndBar.yAxis[0].max = parseInt(Math.max.apply(Math, this.barChartData.value) * 1.1)
      this.lineAndBar.yAxis[1].max = parseInt(Math.max.apply(Math, this.barChartData.profit) * 1.1)
      this.lineAndBar.yAxis[0].interval = parseInt(Math.max.apply(Math, this.barChartData.value) / 5) 
      this.lineAndBar.yAxis[1].interval = parseInt(Math.max.apply(Math, this.barChartData.profit) / 5) 
      this.lineAndBar.xAxis[0].data = this.barChartData.name
      this.lineAndBar.series[0].data = this.barChartData.value
      this.lineAndBar.series[1].data = this.barChartData.profit
      
      this.$emit('done')
    },
  },
}
</script>
